import { CartContext } from "@/setup/cartProvider";
import { useTheme } from "@emotion/react";
import {
  Box,
  Center,
  createStyles,
  Grid,
  Group,
  Image,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { FaCircle } from "@react-icons/all-files/fa/FaCircle";
import { FiCircle } from "@react-icons/all-files/fi/FiCircle";
import { FiCreditCard } from "@react-icons/all-files/fi/FiCreditCard";
import React, { useContext, useEffect, useState } from "react";

function ImageMethod({ item }) {
  return item?.image ? <Image src={item?.image} /> : <FiCreditCard />;
}

const useStyles = createStyles((theme) => ({
  container: {
    flexDirection: "column",
    borderColor: "rgba(0,0,0,0.15)",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: "0.5rem",
  },
  button: {
    borderBottom: 1,
    borderColor: "rgba(0,0,0,0.15)",
    borderStyle: "solid",
    padding: 10,
    width: "100%",
  },
  colorChecked: {
    color: theme.colors.main[5],
  },
}));

export default function CheckBoxPaymentMethod({ defaultCheck, keyItem }) {
  const { classes } = useStyles();

  const { listPaymentMethod, setPaymentSelected } = useContext(CartContext);

  const [checkedItem, setCheckedItem] = useState({
    [keyItem]: "",
  });

  useEffect(() => {
    if (
      defaultCheck &&
      listPaymentMethod?.length > 0 &&
      !checkedItem[keyItem]
    ) {
      setPaymentSelected(listPaymentMethod[0]);
      setCheckedItem(listPaymentMethod[0]);
    }
  }, [defaultCheck, listPaymentMethod]);

  const onChecked = (item) => {
    setPaymentSelected(item);
    setCheckedItem(item);
  };

  // const { theme } = useTheme();

  return (
    <Grid className={classes.container}>
      {listPaymentMethod?.map((item, index) => (
        <Box key={item?._id}>
          <UnstyledButton
            className={classes.button}
            sx={listPaymentMethod?.length === index + 1 && { borderBottom: 0 }}
            onClick={() => onChecked(item)}
          >
            <Group>
              <Box>
                {checkedItem[keyItem] === item[keyItem] ? (
                  <FaCircle className={classes.colorChecked} />
                ) : (
                  <FiCircle />
                )}
              </Box>
              <Center>
                <ImageMethod item />
                <Text sx={{ paddingLeft: 10 }}>{item?.ten}</Text>
              </Center>
            </Group>
          </UnstyledButton>
          {item?.payment_service === "bank" &&
            checkedItem[keyItem] === item[keyItem] && (
              <Box
                sx={{ padding: 10 }}
                style={{
                  borderBottom: 1,
                  borderBottomColor: "rgba(0,0,0,0.15)",
                  // borderStyle: "solid",
                  borderBottomStyle: "solid",
                }}
              >
                <Text align="center" sx={{ marginBottom: 10 }}>
                  Chuyển khoản vào tài khoản của ZeMor Coffee
                </Text>
                <Text align="center" sx={{ marginBottom: 10 }}>
                  Tên tài khoản: {item?.bank_configs?.bank_name?.toUpperCase()}
                </Text>
                <Text align="center" sx={{ marginBottom: 10 }}>
                  Số tài khoản: {item?.bank_configs?.bank_account_number}
                </Text>
                <Text align="center" sx={{ marginBottom: 10 }}>
                  Nội dung chuyển khoản: [SDT] [Khoảng cách] [Mã đơn hàng] [Ghi
                  chú (nếu có)]
                </Text>
              </Box>
            )}
        </Box>
      ))}
    </Grid>
  );
}

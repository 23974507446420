/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Title } from "@mantine/core";
import Link from "next/link";
import React from "react";

function TitleCus({ children }) {
  return (
    <Box
      sx={(theme) => ({
        margin: "70px 0 20px",
        [theme.fn.smallerThan("md")]: {
          margin: "30px 15px",
        },
      })}
    >
      <Title
        order={2}
        size="h2"
        align="center"
        w="100#"
        sx={(theme) => ({
          transition: "0.3s",
          color: theme.colors.second[3],
          "&:hover": {
            color: theme.colors.second[3],
          },
        })}
      >
        {children}
      </Title>
    </Box>
  );
}

export default TitleCus;

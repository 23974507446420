import removeVietnameseAccent from "@/utils/removeVietnameseAccent";
import { Group, Select, Text } from "@mantine/core";
import React, { forwardRef, useEffect, useState } from "react";
const SelectItem = forwardRef(({ label, value, ...others }, ref) => {
  console.log("🚀 ~ file: index.js:5 ~ SelectItem ~ value:", value);
  return (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size="sm">{value}</Text>
        </div>
      </Group>
    </div>
  );
});

export default function SelectComponent(
  {
    data,
    valueKey,
    labelKey = "",
    placeholder = "",
    label,
    onChange,
    autoSelectDefault = false,
    valueDefault,
    required = false,
    formValidate,
    sx,
  },
  props
) {
  const [dataSource, setDataSource] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [value, setValue] = useState("");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (data?.length > 0) {
      const newList = data?.map((item) => {
        const newItem = {
          ...{
            value: item[valueKey] ? item[valueKey] : " ",
            label: item[labelKey] ? item[labelKey] : " ",
            valueShow: item[labelKey] ? item[labelKey] : " ",
          },
        };
        return newItem;
      });
      setDataSource(newList);
    }
  }, [data, labelKey, valueKey]);

  useEffect(() => {
    if (!valueDefault) {
      setValue(valueDefault);
    }

    if (valueDefault && dataSource?.length > 0 && dataSource[0]?.value !== "") {
      let item = dataSource.find((e) => {
        return valueDefault === e?.value;
      });
      if (!item) {
        item = {
          value: "",
          label: "",
        };
      }
      setValue(item?.value);
      if (typeof onChange === "function") {
        onChange(item?.value);
      }
      return;
    }
    if (autoSelectDefault && dataSource?.length > 0) {
      setValue(dataSource[0]?.value);
      if (typeof onChange === "function") {
        onChange(dataSource[0]?.value);
      }
    }
  }, [dataSource, valueDefault]);

  const onChangeValue = (value) => {
    setValue(value);
    if (typeof onChange === "function") {
      onChange(value);
    }
  };

  // useEffect(() => {
  //   if (searchValue) {
  //     const newList = data?.map((item) => {
  //       const newItem = {
  //         ...{
  //           value: item[valueKey] ? item[valueKey] : " ",
  //           label: item[labelKey] ? item[labelKey] : " ",
  //         },
  //       };
  //       return newItem;
  //     });
  //     const newDataSource = newList.filter((item) => {
  //       const itemValue = removeVietnameseAccent(item?.value);
  //       return itemValue.includes(searchValue?.toLowerCase());
  //     });
  //     setDataSource(newDataSource);
  //   }
  // }, [searchValue]);

  return (
    <Select
      withAsterisk={required}
      value={value}
      label={label}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      filter={(searchValue, item) => {
        return (
          removeVietnameseAccent(item?.value)
            .toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          removeVietnameseAccent(item?.label)
            .toLowerCase()
            .includes(searchValue?.toLowerCase())
        );
      }}
      placeholder={placeholder}
      itemComponent={SelectItem}
      data={dataSource}
      searchable
      maxDropdownHeight={300}
      nothingFound="Không tìm thấy"
      onChange={onChangeValue}
      sx={{ ...sx }}
      {...props}
      {...formValidate}
    />
  );
}

SelectItem.displayName = "SelectItem";

import PageContainer from "@/common/components/PageContainer";
import { Box, createStyles, Grid, Text, Title } from "@mantine/core";
import Image from "next/image";

import TitleCus from "@/common/Typography/TitleCus";
import { useLocalContext } from "@/setup/locale-provider";
import sanitizeDOMData from "@/utils/sanitizeDOMData";
import appendImageUrlFromAPI from "@/utils/appendImageUrlFromAPI";

export default function WhyChooseHome({ item, isHomeScreen }) {
  function transformLink(picture) {
    let link = picture || null;
    if (link != null) {
      if (link && link.indexOf("http") < 0) {
        link = `${process.env.server_url_report}${link}?access_token=${process.env.public_token}&trustkey=${process.env.trustkey}`;
      }
    }
    return link;
  }
  const { getLabel } = useLocalContext();
  return (
    <Box component="section">
      <Box sx={{ paddingBottom: isHomeScreen ? 0 : 80 }}>
        <TitleCus>{item && getLabel(sanitizeDOMData(item[3]?.title))}</TitleCus>
        {!isHomeScreen && (
          <>
            <Image
              src={transformLink(item[0]?.picture)}
              layout="responsive"
              height="435px"
              width="963px"
              objectFit="cover"
            />
          </>
        )}
        <Box component="section">
          <Grid>
            {Boolean(item) &&
              item.map((item, index) => (
                <>
                  {index >= 0 && index <= 2  && (
                    <Grid.Col sm={4} sx={{ padding: 25 }}>
                      <Image
                        src={appendImageUrlFromAPI({ src: item?.picture, size: "l" })}
                        layout="responsive"
                        height="192px"
                        width="274px"
                        objectFit="cover"
                      />
                      <Title
                        order={4}
                        align="center"
                        sx={(theme) => ({
                          paddingBottom: 15,
                          paddingTop: 15,
                          color: theme.colors.main[5],
                        })}
                      >
                        {getLabel(item.title)}
                      </Title>
                      <Text
                        size={18}
                        style={{ paddingTop: 10, lineHeight: "35px" }}
                        align="justify"
                      >
                        {item?.content &&
                          getLabel(sanitizeDOMData(item?.content))}
                      </Text>
                    </Grid.Col>
                  )}
                </>
              ))}
          </Grid>
        </Box>
        {/* <Title
        order={3}
        align="center"
        sx={(theme) => ({ paddingBottom: 45, color: theme.colors.main[5] })}
      >
      {item?.title}
      </Title>
      <Text
        size={18}
        style={{ paddingTop: 10, lineHeight: "35px" }}
        align="justify"
        >
        {item?.content && sanitizeDOMData(item?.content)}
    </Text> */}
      </Box>
    </Box>
  );
}

function removeVietnameseAccent(str) {
  // Chuyển chuỗi về chữ thường
  str = str.toLowerCase();

  // Loại bỏ các ký tự có dấu
  str = str.replace(/[áàảãạăắằẳẵặâấầẩẫậ]/g, "a");
  str = str.replace(/[éèẻẽẹêếềểễệ]/g, "e");
  str = str.replace(/[óòỏõọôốồổỗộơớờởỡợ]/g, "o");
  str = str.replace(/[íìỉĩị]/g, "i");
  str = str.replace(/[úùủũụưứừửữự]/g, "u");
  str = str.replace(/[ýỳỷỹỵ]/g, "y");
  str = str.replace(/đ/g, "d");

  // Loại bỏ các ký tự khác
  str = str.replace(/[^a-z0-9\s]/gi, "");

  // Loại bỏ khoảng trắng thừa
  str = str.trim().replace(/\s+/g, " ");

  return str;
}

export default removeVietnameseAccent;
